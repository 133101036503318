/*
所有的接口请求
*/
import http from '@/utils/http';
const isOpen = false;
const mpDate = {
  "notice":{
    date:{
      id:'公告ID',
      title: '标题',
      content:'内容  文本域',
      websiteNode:'站点编号',
      createTime:'创建时间'
    },
    "statusCode":"100000",
    "statusStr":"请求成功",
  },
  "check_card":{
    date:{
      "sign":"abcdef",
      "card_info":{
        cardNum:'123456',
        cardTitle:'湖中皇大闸蟹礼券A套餐',
        money:'1388',
        id: '123456'
      }
    },
    "statusCode":"100000",
    "statusStr":"请求成功",
  },
  "check_card1":{
    date:{},
    "statusCode":"100514",
    "statusStr":"请求成功",
  },
  "hzh_order_submit":{
    date:{
      "orderInfo":{
        "orderCode":"330120220818000004"
      }
    },
    "statusCode":"100000",
    "statusStr":"请求成功",
  },
  "orderInfo_by_card":{
    date:{
      "orderInfo": {
        "id": "4",
        "orderCode": "330120220818000004",
        "orderStatus": 2,
        "barCode": "33012022081736000016",
        "cardvoucherId": "227",
        "orderContent": "",
        "orderType": 2,
        "createTime": "2022-08-18 14:32:46",
        "customName": "",
        "customMobile": "13672211803",
        "receiveAddress": "111",
        "customRequest": "111",
        "orderMoney": "3600",
        "finishTime": "",
        "websiteNode": "",
        "creator": "",
        "cancelTime": "",
        "websiteName": ""
      }
    },
    "statusCode":"100000",
    "statusStr":"请求成功",
  }
}
function htt (method) {
  return new Promise((resolve,reject) => {
    setTimeout(() => {
      resolve(mpDate[method])
    }, 2000);
  })
}

// 获取公告信息
export function getNotice() {
  const params = {
    method: 'notice', 
    websiteNode: 3301, 
  }
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}
// 检查卡状态
export function getCheckCard(data) {
  let params = {
    method: 'check_card', 
    websiteNode: 3301, 
    barCode: '',
    password: '',
    code: ''
  }
  params = Object.assign({},params,data);
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}

// 提交订单
export function getOrderSubmit(data){
  let params = {
    method: 'hzh_order_submit', 
    websiteNode: 3301, 
    barCode: '',
    customName: '',
    customMobile: '',
    receiveAddress:'',
    customRequest:'',
    sign:''
  }
  params = Object.assign({},params,data);
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}

// 查看兑换信息、卡号密码
export function getOrderByCard(data){
  let params = {
    method: 'orderInfo_by_card', 
  }
  params = Object.assign({},params,data);
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}
// 查看兑换信息、--订单编码
export function getOrderByOrderCode(data){
  let params = {
    method: 'orderInfo_by_orderCode', 
  }
  params = Object.assign({},params,data);
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}
// 获取图形验证码 --订单编码
export function getCode(data){
  let params = {
    method: 'get_code', 
  }
  params = Object.assign({},params,data);
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}


// 获取微信配置
export function getWxConfig(data){
  let params = {
    method: 'weixin_config', 
  }
  params = Object.assign({},params,data);
  if(isOpen){
    return htt(params.method);
    
  }
  return http('post', params);
}
