
import Qs from 'qs'
import axios from 'axios'
import { Toast } from 'vant'
// const url = 'http://testapp.guoss.cn/gss_api/server/api.do'
// 
const url = 'https://hzhh5.91eqm.com/server/api.do'
// http://192.168.8.49:8081/hzh_api_war_exploded/server/api.do?method=notice&websiteNode=3301
// axios.defaults.baseURL = url
/* eslint-disable */
axios.defaults.header = {
  'Content-Type': 'application/json;charset=utf-8',
  'Access-Control-Allow-Origin': '*'
}
axios.defaults.timeout = 8000;
axios.defaults.retry = 4;
axios.defaults.retryDelay = 5000;

axios.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.fail('服务端异常！')
    return Promise.reject(res)
  }
  if (res.status != 200) {
    if (res.statusText) Toast.fail(res.statusText)
    
    return Promise.reject(res.data)
  }
  // let result = res.data;
  // if(result.statusCode === '100000'){
    
  // }
  return res.data
})
axios.defaults.transformRequest = [function (data) {
  data = Qs.stringify(data)
  return data
}]

axios.defaults.transformResponse = [function (data) {
  data = JSON.parse(data)
  return data
}]


function http(type,params) {
  return new Promise((resolve, reject) => {
    type = type.toUpperCase();
    if(type === 'POST'){
      axios.post( url, params)
      .then((res) => {
        console.log(res)
        resolve(res)
      }).catch(err => {
        console.log(err)
        if (err == 'Error: timeout of 5000ms exceeded') {
          console.log('服务器请求超时')
          return
        }
      })
    }
    if(type === 'GET'){
      axios.get( url,{
        params: params
      })
      .then((res) => {
        resolve(res)
      }).catch(err => {
        if (err == 'Error: timeout of 5000ms exceeded') {
          console.log('服务器请求超时')
          return
        }
      })
    }
  })
}
export default http
